<template>
    <div class="history">
        <el-timeline :reverse="true">
            <el-timeline-item v-for="(item, index) in history" :key="index" :timestamp="formatDate(item.date)"
                              placement="top">
                <el-card>
                        <span class="timeline-title">
                            <template v-if="item.title">{{ item.title }}</template>
                            <template v-else>发生了未知的变化</template>
                        </span>
                    <template v-if="item.detail">
                        <ul class="timeline-summary">
                            <li v-for="(line, i) in item.detail.split('\n')" :key="i">
                                {{ line }}
                            </li>
                        </ul>
                    </template>
                </el-card>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>

<script>
export default {
    name: 'History',
    data () {
        return {
            history: []
        }
    },
    methods: {
        formatDate (date) {
            function fill (num) {
                return num >= 10 ? num : '0' + num
            }

            if (typeof date === "string") {
                return date
            }

            return date.getFullYear() + '年' + fill(date.getMonth() + 1) + '月' + fill(date.getDate()) + '日'
        },
        load () {
            this.utils.getJson(this.$store.state.api.history, {}).then(response => {
                this.history.splice(0, this.history.length)
                console.log(response)
                response.forEach(item => this.history.push(item))
            })
        }
    },
    mounted () {
        this.load()
    }
}
</script>

<style scoped>
.history {
    max-width: 640px;
    margin: 0 auto;
    padding: 32px 0;
}

.timeline-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
}

.timeline-summary {
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    color: #555555;
}

.el-card {
    margin: 12px 0;
}

.el-card:last-child {
    margin: 0;
}

.el-card__body > p:last-child {
    margin-bottom: 0;
}

ul.timeline-summary {
    padding-inline-start: 24px;
    padding-inline-end: 2px;
    list-style-type: disc;
}
</style>
